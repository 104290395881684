import { PromptService } from './../_shared/services/prompt.service';
import { AuthService } from './../_shared/services/auth.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class HasSelectedOrganizationGuard  {
    constructor(private _auth: AuthService, private _prompt: PromptService){}
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
            if(this._auth.selectedOrganization == null) {
                this._prompt.error('Select an organization first');
                return false;
            }

            return true;
    }
}
